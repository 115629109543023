.spinner-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--primary) !important;
  animation: blink-bounce 1.6s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(var(--primary-rgb), 0.5);
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink-bounce {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0.5;
    background-color: var(--primary);
    box-shadow: 0 0 5px var(--primary);
  }
  33% {
    transform: translateY(-10px) scale(1.2);
    opacity: 1;
    background-color: var(--info);
    box-shadow: 0 0 15px var(--info);
  }
  66% {
    transform: translateY(10px) scale(1.2);
    opacity: 1;
    background-color: var(--warning);
    box-shadow: 0 0 15px var(--warning);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 0.5;
    background-color: var(--primary);
    box-shadow: 0 0 5px var(--primary);
  }
}

.spinner-text {
  margin-top: 1rem;
  color: var(--body-color);
  font-size: 0.9rem;
}