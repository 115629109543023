.left-align-cell {
    text-align: left;
  }
.left-align-header {
    text-align: left;
}
.input-wrapper {
    position: relative;
    width: 271px;
 }
 .input-icon {
    color: #191919;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
 }

 .search-icon {
    right: 15px;
    z-index: 999999;
 }

 .eye-icon {
    right: 15px;
    z-index: 999999;
 }

.close-icon {
    right: 60px;
    z-index: 999999;
}
.left-align-cell {
/* Otros estilos para left-align-cell si los hay */

    /* Estilos para reducir el espacio entre párrafos */
    .paragraph {
        margin: -5px 0; /* Ajusta estos valores según tus preferencias */
    }
}

.right-align-cell {
/* Otros estilos para left-align-cell si los hay */

    /* Estilos para reducir el espacio entre párrafos */
    .paragraph {
        margin: 0 -5px; /* Ajusta estos valores según tus preferencias */
    }
}
.accordion-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
/* Estilos para la imagen */
.image-container {
    width: 75px;  /* Ancho deseado */
    height: 75px; /* Altura deseada */
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda los límites */
    border-radius: 50%; /* Para hacer la imagen circular (opcional) */
  }

  /* Estilos para la imagen dentro del contenedor */
  .image-container img {
    width: 100%; /* Hace que la imagen ocupe el 100% del contenedor */
    height: 100%; /* Hace que la imagen ocupe el 100% del contenedor */
    object-fit: cover; /* Ajusta la imagen dentro del contenedor sin distorsionarla */
  }

.card iframe {
    width: 100%;
    height: 315px;
}


/* styles.css o tu archivo de estilos principal */
.quill-editor-container {
    height: 450px; /* Ajusta la altura según sea necesario */
    margin-bottom: 15px; /* Agrega un margen inferior opcional */
}

.paragraph-wrapper {
    display: flex;
    align-items: center;
}

.paragraph-right {
    margin: 0px 0;
    width: 120px; /* Ancho fijo para las etiquetas de texto */
    text-align: right; /* Alinea el texto a la derecha */
}
.paragraph-left {
    margin: 0px 0;
    width: 120px; /* Ancho fijo para las etiquetas de texto */
    text-align: left; /* Alinea el texto a la derecha */
}

.color-citoplus-primario {
    color: #ff5e17 !important;
}

.btn-citoplus-danger {
    background-color: #ff5e17 !important;
    color: white !important;
}

.btn-citoplus-primary {
    background-color: #0f2242 !important;
    color: white !important;
}

.btn-citoplus-secondary {
    background-color: #f5f9fc !important;
    color: #0f2242 !important;
}

.btn-citoplus-success {
    background-color: #0f2242 !important;
    color: white !important;
}

.btn-citoplus-warning {
    background-color: #ffb245 !important;
    color: white !important;
}

.btn-citoplus-info {
    background-color: #1a63a5 !important;
    color: white !important;
}

.btn-citoplus-light {
    background-color: #f5f9fc !important;
    color: #0f2242 !important;
}

.btn-citoplus-dark {
    background-color: #202023 !important;
    color: white !important;
}

.btn-citoplus-white {
    background-color: white !important;
    color: #0f2242 !important;
}

.btn-citoplus-black {
    background-color: #0f2242 !important;
    color: white !important;
}

.btn-citoplus-dropdown-success {
  background-color: #0f2242;
  color: white;
  border: none;
}

.btn-citoplus-dropdown-success:hover {
  background-color: #0f2242;
}

.btn-primary {
    background-color: #0f2242 !important;
    color: white !important;
}

.btn-danger {
    background-color: #ff5e17 !important;
    color: white !important;
}

.transparent-button {
    background-color: transparent;
  }


.receipt {
    width: 250px;
    margin: 0 auto;
    border: 1px solid #000;
    padding: 10px;
    text-align: center;
    background-color: white;
    color: black !important;
}
.receipt h2 {
    margin: 5px 0;
}
.receipt p {
    margin: 3px 0;
}

.swal-button--confirm {
    background-color: #0f2242 !important;
    color: white !important;
}


.dlab-wrapper  {
    text-align: center;
}

.badge-citoplus-primary {
    background-color: #0f2242 !important;
    color: white !important;
}

.badge-citoplus-secondary {
    background-color: #f5f9fc !important;
    color: #0f2242 !important;
}

.badge-citoplus-success {
    background-color: #0f2242 !important;
    color: white !important;
}

.badge-citoplus-warning {
    background-color: #ffb245 !important;
    color: white !important;
}

.image-container {
  position: flex;
}

.background-image-card-vigilante {
  border-radius: 25px;
  /* background-image: url("../images/card/1.jpg");*/
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 125px;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Esto permite hacer clic en los enlaces debajo del overlay */
}

.number-overlay {
  font-size: 4em; /* Ajusta el tamaño del número según tus necesidades */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Añade sombra para mejor legibilidad */
}

.note_card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 70px) !important;
}

.load-more-btn {
  background-color: #0f2242;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.load-more-btn:hover {
  background-color: #0f2242;
  color: white;
}

.card-header-comun {
  background-color: #ff5e17 !important;
  color: white !important;
}

.card-header-visitantes {
  background-color: #1a63a5 !important;
  color: white !important;
}

.card-header-privada {
  background-color: #f5f9fc !important;
  color: black;
}

.card-header-discapacitados {
  background-color: #0f2242 !important;
  color: white !important;
}

.card-header-bicicletero {
  background-color: #ffb245 !important;
  color: white;
}

.text-wrap {
  word-break: break-all; /* Permite que las palabras largas se dividan en cualquier punto */
  overflow-wrap: break-word; /* Permite que las palabras largas se dividan */
  white-space: normal; /* Permite que el texto se ajuste automáticamente */
  text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es demasiado largo */
  overflow: hidden; /* Oculta el desbordamiento del texto */
}

.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.ql-align-justify {
  text-align: justify;
}

.bg-citoplus-blue {
  background-color: #0f2242 !important;
}

/* CheckboxOnOff.css */
.switch-container {
  display: flex;
  align-items: center;
}

.switch-label {
  font-size: 16px;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0f2242;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-state {
  font-size: 16px;
}

.call-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Espaciado entre los elementos */
  color: #333;
}

.call-info {
  display: flex;
  align-items: center;
  gap: 8px; /* Espaciado entre el icono y el texto */
}

.call-icon {
  /*font-size: 20px; /* Tamaño del icono */
  color: #0f2242; /* Color azul para los iconos */
}

.call-details {
  /*font-weight: 600;*/
  color: #333;
}

.arrow {
  /*font-size: 24px; /* Tamaño de la flecha */
  color: #0f2242;
}

.call-duration {
  font-weight: 600;
  color: #333;
  margin-left: 5px; /* Espacio entre la flecha y la duración */
  background-color: #f1f1f1; /* Fondo ligero */
  padding: 2px 8px; /* Espaciado alrededor del texto */
  border-radius: 5px; /* Bordes redondeados */
}

/* Estilos para inputs parpadeantes */
.blink-input {
  animation: blink-border 1s linear 2; /* 2 repeticiones (2 segundos en total) */
}

.blink-label {
  animation: blink-text 1s linear 2; /* 2 repeticiones (2 segundos en total) */
}

@keyframes blink-border {
  0%, 100% { 
    border-color: #ced4da;
    box-shadow: none;
  }
  50% { 
    border-color: var(--primary);
    box-shadow: 0 0 5px var(--primary);
  }
}

@keyframes blink-text {
  0%, 100% { color: #212529; }
  50% { color: var(--primary); }
}

/* Estilos base para sub-items - removiendo líneas y guiones */
.dlabnav .metismenu > li > ul > li > a.sub-menu-link {
  display: block;
  position: relative;
  font-size: 14px;
  transition: all 0.3s ease;
  margin: 4px 0;
  color: var(--nav-text);
  border: none;  /* Removemos el borde */
}

/* Estilo hover para sub-items */
.dlabnav .metismenu > li > ul > li > a.sub-menu-link:hover {
  background: var(--rgba-primary-1);
  color: var(--primary);
  border: none;  /* Removemos el borde en hover */
}

/* Estilo para sub-item activo */
.dlabnav .metismenu > li > ul > li.mm-active > a.sub-menu-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  font-weight: 500;
  border: none;  /* Removemos el borde en estado activo */
}

/* Remover explícitamente cualquier línea o guion */
.dlabnav .metismenu > li > ul > li > a.sub-menu-link::before,
.dlabnav .metismenu > li > ul > li > a.sub-menu-link::after {
}

/* Modo oscuro específico - sin líneas */
[data-theme-version="dark"] .dlabnav .metismenu > li > ul > li > a.sub-menu-link {
  color: rgba(255, 255, 255, 0.7);
  border: none;
}

[data-theme-version="dark"] .dlabnav .metismenu > li > ul > li > a.sub-menu-link:hover,
[data-theme-version="dark"] .dlabnav .metismenu > li > ul > li.mm-active > a.sub-menu-link {
  background: rgba(59, 127, 191, 0.1);
  color: #fff;
  border: none;
}

/* Para modo claro */
.caja-card-active {
  border: 2px solid var(--primary) !important;
}

/* Para modo oscuro */
[data-theme-version="dark"] .caja-card-active {
  border: 2px solid var(--primary) !important;
}